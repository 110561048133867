@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth
}

@import "~swiper/css";
@import "~swiper/css/effect-fade";
@import "~swiper/css/navigation";
@import "~swiper/css/pagination";
@import "~aos/dist/aos.css";

.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 30px;
  border-radius: 5px;
  transition: 0.4s;
  background-color: #ED1B24;
}

.swiper-wrapper {
  align-items: center !important;
}


.img_pos {
  object-position: "0rem 40rem"
}

/* @media only screen and (max-width: 600px) {
  .img_pos {
     object-position: "40rem 40rem"
  }
} */